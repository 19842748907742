import { combineReducers } from "@reduxjs/toolkit"

import auth from './authReducers'
import project from './projectReducers'
import alert from './alertReducers'

export default combineReducers({
    // register all reducers here
    auth,
    project,
    alert
})