import axios from "axios";
// eslint-disable-next-line no-undef
const API = process.env.REACT_APP_API_URL;

class Auth {
    /**
     * Auth class for handling authentication related tasks
     */

    constructor() {
        /**
         * constructor method for Auth class
         * 
         */
    }

    isAuthenticated() {
        /**
         * isAuthenticated method is used to check if user is authenticated or not
         * 
         * @returns {boolean} true if user is authenticated, false if user is not authenticated
         */

        return new Promise((resolve, reject) => {
            // check if access_token is present in local storage
            if (localStorage.getItem('access_token')) {
                // if access_token is present, verify the access_token
                this.VerifyToken(localStorage.getItem('access_token'))
                    .then((res) => {
                        if (res) {
                            // if token is valid, return true
                            console.info('[Auth] access_token is valid')
                            resolve(true)
                        }
                    }).catch(() => {
                        // if access_token is invalid, remove access_token from local storage
                        console.info('[Auth] access_token is invalid')
                        localStorage.removeItem('access_token')
                        this.GetAccessToken(localStorage.getItem('refresh_token'))
                            .then((res) => {
                                // if new access_token return, set access_token to local storage
                                console.info('[Auth] access_token is set from refresh_token')
                                localStorage.setItem('access_token', res);
                                resolve(true)
                            }).catch(() => {
                                // if refresh_token is invalid, remove refresh_token from local storage
                                console.info('[Auth] refresh_token is invalid')
                                localStorage.removeItem('refresh_token')
                                reject(false)
                            })
                    })
            } else if (localStorage.getItem('refresh_token')) {
                // if no access_token is present, check if refresh_token is present
                console.info('[Auth] access_token is not present')
                this.GetAccessToken(localStorage.getItem('refresh_token'))
                    .then((res) => {
                        // if new access_token return, set access_token to local storage
                        console.info('[Auth] access_token is set')
                        localStorage.setItem('access_token', res);
                        resolve(true)
                    }).catch(() => {
                        // if refresh_token is invalid, remove refresh_token from local storage
                        console.info('[Auth] refresh_token is invalid')
                        localStorage.removeItem('refresh_token')
                        reject(false)
                    })
            } else {
                reject('No token found')
            }
        })

    }

    VerifyToken(token = localStorage.getItem('access_token')) {
        /**
         * Verify token is used to verify and validate token from server
         * 
         * @param {string} token JWT token
         * @returns {boolean} true if token is valid, false if token is invalid
         * 
         */

        return new Promise((resolve, reject) => {
            // check token is valid
            axios.post(API + '/verify-token', {}, {
                headers: {
                    'AUTH-TOKEN': token,
                }
            }).then(res => {
                if (res.status === 200) {
                    // if token is valid, return true
                    resolve(true)
                }
                else {
                    // if token is invalid, return false
                    resolve(false)
                }
            }).catch(() => {
                // any error, return false
                reject(false)
            })
        })
    }

    GetAccessToken(refresh_token = localStorage.getItem('refresh_token')) {
        /**
         * GetAccessToken is used to get access token from server using refresh token
         * 
         * @param {string} refresh_token JWT refresh token
         * @returns {string} access_token JWT access token
         * 
         * 
         * @todo: add get access token from server with workspace
         * 
         */

        return new Promise((resolve, reject) => {
            // get access token from server

            axios.post(API + '/refresh-token', {}, {
                headers: {
                    'AUTH-TOKEN': refresh_token,
                }
            }).then(res => {
                if (res.status == 200) {
                    // if refresh token is valid, return access_token
                    resolve(res.data.access_token)
                }
                else {
                    // if refresh token is invalid, return false
                    reject(false)
                }
            }
            ).catch(() => {
                // any error, return false
                reject(false)
            })
        })
    }

}

export default new Auth;