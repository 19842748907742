/* eslint-disable react/react-in-jsx-scope */
import './App.css';

import AppRoutes from './ Routes';

// Redux
import { Store } from './redux/store';
import { Provider } from 'react-redux'
import ApiInterceptor from './services/axios/interceptor';

function App() {
    return (
        <Provider store={Store}>
            <AppRoutes />
            <ApiInterceptor />
        </Provider>
    )
}

export default App;
