import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/actions/alertAction";

import api from ".";


const ApiInterceptor = () => {
    const dispatch = useDispatch();

    // Add a response interceptor
    api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.status === 202) {
            dispatch(showAlert('success', response.data.message));
        }
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
            dispatch(showAlert('danger', error.response.data.message));
        }
        if (typeof error.response.data.message === 'string')
            dispatch(showAlert('danger', error.response.data.message));
        return Promise.reject(error);
    });
}

export default ApiInterceptor;