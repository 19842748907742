export default function reducer(state = {
    currentProject: null
}, action) {
    switch (action.type) {
        case 'SET_CURRENT_PROJECT':
            // -1 creates a new project
            if (action.payload === "-1") {
                window.location.href = "/projects";
            }

            return {
                ...state,
                currentProject: action.payload
            }
        default:
            return state;
    }
}
