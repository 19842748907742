import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearAlert } from "../redux/actions/alertAction";

function Alert(props) {
    const { alert, dispatch } = props;
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                bottom: "0",
                right: "0",
                margin: "0 10px 10px 0",
            }}
        >
            {
                alert && alert.map((alert, index) => {
                    setTimeout(() => {
                        dispatch(clearAlert(index))
                    }, 5000);
                    return (
                        <div
                            key={index}
                            className={`alert alert-${alert.type} `}
                            role="alert"
                            style={{
                                margin: "10px",
                            }}
                        >
                            {alert.message}
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => dispatch(clearAlert(index))}
                            >
                            </button>
                        </div >
                    )
                })
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        alert: state.alert
    };
}

Alert.propTypes = {
    alert: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(Alert);
