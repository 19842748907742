export default function reducer(state = {
    isAuthenticated: false,
    userId: null,
}, action) {
    switch (action.type) {
        case 'AUTH_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                userId: action.payload.userId,
            };
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                isAuthenticated: false,
            };
        default:
            return state;
    }
}