import jwt_decode from "jwt-decode";

export function authenticateUser() {
    let userId = jwt_decode(localStorage.getItem('access_token'));
    return {
        type: 'AUTH_SUCCESS',
        payload: {
            userId: userId.sub
        }
    };
}

export function logoutUser() {
    return {
        type: 'LOGOUT_SUCCESS',
    };
}