export default function reducer(state = [], action) {
    switch (action.type) {
        case 'SET_ALERT':
            console.log(action.payload, 'alert');
            return [...state, action.payload];
        case 'CLEAR_ALERT':
            return state.filter((alert, index) => index !== action.payload.id);
        case 'CLEAR_ALL_ALERTS':
            return [];
        default:
            return state;
    }
}
