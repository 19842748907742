export function showAlert(type, message) {
    return {
        type: 'SET_ALERT',
        payload: { type, message }
    };
}

export function clearAlert(id) {
    return {
        type: 'CLEAR_ALERT',
        payload: { id }
    };
}

export function clearAllAlerts() {
    return {
        type: 'CLEAR_ALL_ALERTS'
    };
}
