import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, /*useNavigate*/ } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Auth from "./services/Auth";
import { authenticateUser, logoutUser } from "./redux/actions/authActions";
import api from "./services";
import Loading from "./components/loading";
import Alert from "./components/alert";

const Forms = lazy(() => import("./pages/forms"));
const Form = lazy(() => import("./pages/forms/form"));
const AuthPage = lazy(() => import("./pages/auth/auth"));
const VerifyEmail = lazy(() => import("./pages/auth/verifyEmail"));
const ResetPassword = lazy(() => import("./pages/auth/resetPassword"));
const SetPassword = lazy(() => import("./pages/auth/setPassword"));
const NotFound = lazy(() => import("./pages/errors/40x"));
// const Home = lazy(() => import("./pages/home"));
const Maintanence = lazy(() => import('./components/maintenance/maintenance'))
const Navbar = lazy(() => import("./components/navbar/navbar"));
const CreateForm = lazy(() => import("./pages/forms/create"));
const Project = lazy(() => import("./pages/projects"));
const ProjectForms = lazy(() => import("./pages/projects/forms"));
const CreateProject = lazy(() => import("./pages/projects/create"));
const Submission = lazy(() => import("./pages/forms/submission"));
const Public = lazy(() => import("./pages/forms/public"));
const ThankYou = lazy(() => import("./pages/forms/public/thank_you"));

// const navigate = useNavigate()

function AppRoutes(props) {

    const { isAuthenticated, dispatch } = props;


    useEffect(() => {

        // check url is /login or not
        if (window.location.pathname === '/login') {
            if (!isAuthenticated) {
                Auth.isAuthenticated()
                    .then((response) => {
                        console.log(response);
                        api.defaults.headers.common['AUTH-TOKEN'] = localStorage.getItem('access_token')
                        dispatch(authenticateUser())
                    }).catch((error) => {
                        console.log(error);
                        dispatch(logoutUser());
                    })
            }
        } else {
            // check access_token is in local storage or not if yes then authenticate user
            if (localStorage.getItem('access_token')) {
                // if user is authenticated, set access_token to header
                api.defaults.headers.common['AUTH-TOKEN'] = localStorage.getItem('access_token')
                // set user is authenticated
                dispatch(authenticateUser())
            }
        }
    }, [isAuthenticated]);

    return (
        <Router>
            <Suspense fallback={<Loading />}>

                {
                    !isAuthenticated ?
                        <Routes>
                            <Route path="/" element={<Maintanence />} />
                            <Route path="/login" element={<AuthPage />} />
                            <Route path="/register" element={<AuthPage />} />
                            <Route path="/verify-email/:token" element={<VerifyEmail />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/set-password/:token" element={<SetPassword />} />
                            <Route path="/:slug" element={<Public />} />
                            <Route path="*" element={<NotFound />} />
                            <Route path="/thank-you" element={<ThankYou />} />
                        </Routes>
                        :
                        <>
                            <Navbar />
                            <Routes>
                                <Route path="/" element={<Navigate to="/forms" />} />
                                <Route path="/forms/create" element={<CreateForm />} />
                                <Route path="/forms" element={<Forms />} />
                                <Route path="/forms/:id" element={<Form />} />
                                <Route path="/forms/:id/submissions/:submissionId" element={<Submission />} />

                                <Route path="projects" element={<Project />} />
                                <Route path="projects/create" element={<CreateProject />} />
                                <Route path="projects/:id" element={<CreateProject />} />
                                <Route path="projects/:id/forms" element={<ProjectForms />} />
                                <Route path="/:slug" element={<Public />} />
                                <Route path="*" element={<NotFound />} />
                                <Route path="/thank-you" element={<ThankYou />} />
                            </Routes>
                        </>
                }
                <Alert />
            </Suspense>
        </Router >

    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
})


AppRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(AppRoutes);